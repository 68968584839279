
import { MessageDayModel } from '../../models/messageDay';
import ApiClient from '../ApiClient';
import { NiceResponse } from '../dto/NiceResponse';

export class MessageDayApi {
	constructor(private api: ApiClient) { }

	path = '/message-day';

	async create(req: FormData) {
		
		const resp = await this.api.client.post<NiceResponse<null>>(this.path + '/create', req , { headers: { 'Content-Type': 'multipart/form-data' } });
		return resp.data;
	}

	async update(req: FormData, id: string) {
		const resp = await this.api.client.put<NiceResponse<null>>(this.path + '/update/' + id, req, { headers: { 'Content-Type': 'multipart/form-data' } });
		return resp.data;
	}

	async delete(id: string) {
		const resp = await this.api.client.delete<NiceResponse<null>>(this.path + '/delete/' + id);
		return resp.data;
	}

	async get(skip: number, take: number) {
		const resp = await this.api.client.get<NiceResponse<MessageDayModel[]>>(this.path + '/list?skip=' + skip + '&take=' + take);
		return resp.data;
	}
}