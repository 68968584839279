/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import styles from './meditations.module.css';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
require('moment/locale/ru');

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { Button } from '../../components/UI/Button/Button';
import { ReactComponent as EditIco } from './icon/edit.svg';
import { MeditationsModel } from '../../models/meditations';
import { AppSettings } from '../../appSettings';
import MeditationPopUP from '../../components/popUp/Meditations/Meditations';
import { truncateText } from '../../utils/text';

const Meditations: React.FC = observer(() => {

	const api = useApi();
	const store = useStores();

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);

	const [showForm, setShowForm] = useState<boolean>(false);

	const [meditations, setMeditations] = useState<MeditationsModel[]>([]);

	useEffect(() => {
		loadMeditations();
	}, []);

	const loadMeditations = async () => {
		try {
			store.notificationsStore.showPreloader();
			const {body} = await api.meditations.getMeditations(page * rowsPerPage, rowsPerPage);
	
			if (body) {
				setMeditations(body);
			}

		} catch (e) {
			store.notificationsStore.setError('Ошибка при загрузке')
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	}

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	}

	const closeForm = () => {
		setShowForm(false);
		setMeditation(null);
	}

	const update = () => {
		loadMeditations();
	}

	const [meditation, setMeditation] = useState<MeditationsModel | null>(null);

	const editMeditation = (meditationSet: MeditationsModel) => {
		setMeditation(meditationSet);
		setShowForm(true);
	}

	return (
		<>
			{showForm && <MeditationPopUP onClose={closeForm} update={update} item={meditation}/>}
			<div className={styles.container}>
				<div className={styles.pageName}>
					<div className={styles.top}>
						<div className={styles.title}>
							<h2>Медитации</h2>
						</div>
						<Button style={{maxWidth: 150}} appearance="auth" onClick={() => setShowForm(showForm ? false : true)}>Добавить</Button>
					</div>

				</div>

				<div style={{ width: '100%', maxWidth: 1500, margin: '5px' }}>

					<Paper sx={{ width: '100%', maxWidth: 1500, margin: '5px', overflowX: 'auto' }}>
						<TableContainer 
							style={{marginTop: 20, overflowX: 'auto'}}>
							<Table sx={{ minWidth: 320 }}>
								<TableHead>
									<TableRow>
										<TableCell>Название</TableCell>
										<TableCell align="right">Описание</TableCell>
										<TableCell align="right">Полное описание</TableCell>
										<TableCell align="right">Изображение</TableCell>
										<TableCell align="right">Аудио</TableCell>
										<TableCell align="right">Группа</TableCell>
										<TableCell align="right">Действие</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{meditations && meditations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row) => (
											<TableRow
												key={row.id}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<>
													<TableCell component="th" scope="row">
														{row.name}
													</TableCell>
													<TableCell align="right">{row.description}</TableCell>
													<TableCell align="right">{truncateText(row.full_description, 100)}</TableCell>
													<TableCell align="right"><img src={AppSettings.api.url + row.image} width={200}/></TableCell>
													<TableCell>
														<audio controls style={{maxWidth: 250}}>
															<source src={row.url} type="audio/mpeg" />
														</audio>
													</TableCell>
													<TableCell align="right">{row.group_id}</TableCell>

													<TableCell align="right">
														<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
															<EditIco className={styles.tagDeleteIco} 
																onClick={() => {
																	editMeditation(row);
																}}
															/>
														</div>
													</TableCell>

												</>

											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
						<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
						<TablePagination
							labelRowsPerPage="Строк на странице"
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={meditations ? meditations.length : 0}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Paper>

				</div>
				

			</div>

		</>
	);
});

export default Meditations;