import { makeAutoObservable } from 'mobx';
import ApiClient from '../api/ApiClient';
import AuthStore from './authStore';
import NotificationsStore from './notificationsStore';
import UserStore from './userStore';
import ApiClientProps from '../api/ApiClientProps';

export default class MainStore {
	authStore: AuthStore;
	userStore: UserStore;
	notificationsStore: NotificationsStore;
	apiClient: ApiClient;

	constructor(apiClient: ApiClientProps) {
		makeAutoObservable(this);
		
		this.apiClient = new ApiClient(apiClient, this);
		this.authStore = new AuthStore(this);
		this.userStore = new UserStore(this);
		this.notificationsStore = new NotificationsStore(this);
	}
}