import { HeaderProps } from './Header.props';
import styles from './Header.module.css';
import { ReactComponent as Logo } from './muip-logo.svg';

export const Header = ({...props}: HeaderProps): JSX.Element => {

	return (
		<div {...props}>
			<Logo className={styles.logo}/>

			<div className={styles.datesSelect}>
			</div>
		</div>
	);
};