import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './Sidebar.module.css';
import { ReactComponent as Exit } from './ico/exit.svg';
import { ReactComponent as AutotrackingIco } from './ico/autotracking.svg';
import Avatar from '../../UI/Avatar/Avatar';
import { useStores } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import { bannersAccessRoles, faqAccessRoles, meditationsAccessRoles, messageDayAccessRoles, contactsAccessRoles, usersAccessRoles, videosAccessRoles } from '../../../utils/accessRoles';
import ProtectedLink from '../../UI/ProtectedLink/ProtectedLink';

interface SidebarProps {
  className?: string;
}

export const Sidebar: React.FC<SidebarProps> = observer((props) => {
	const navigate = useNavigate();
	const {userStore, authStore} = useStores();

	const paths = {
		profile: '/profile',
		users: '/users',
		meditations: '/meditations',
		banners: '/banners',
		messageDay: 'message-day',
		videos: '/videos',
		faq: '/faq',
		contacts: '/contacts',
	};

	const [name, setName] = React.useState<string>('');
	const [email, setEmail] = React.useState<string>('');

	useEffect(() => {
		if (userStore.user?.name && userStore.user?.email) {
			setName(userStore?.user?.name.length >= 15 ? `${userStore?.user?.name.slice(0, 15)}...` : userStore.user.name)
			setEmail(userStore?.user?.email?.length >= 19 ? `${userStore.user.email.slice(0, 18)}...` : userStore.user.email)
		}

	}, [userStore.user]);

	

	const exit = async () => {
		await authStore.logout();
		navigate('/');
	};

	return (
		<div {...props}>

			<div className={styles.menu}>

				<ProtectedLink to={paths.meditations} roles={meditationsAccessRoles} userRole={userStore?.user?.role as string} label="Медитации" Icon={AutotrackingIco} />
				<ProtectedLink to={paths.banners} roles={bannersAccessRoles} userRole={userStore?.user?.role as string} label="Баннеры" Icon={AutotrackingIco} />
				<ProtectedLink to={paths.messageDay} roles={messageDayAccessRoles} userRole={userStore?.user?.role as string} label="Послания дня" Icon={AutotrackingIco} />
				<ProtectedLink to={paths.videos} roles={videosAccessRoles} userRole={userStore?.user?.role as string} label="Видео" Icon={AutotrackingIco} />
				<ProtectedLink to={paths.faq} roles={faqAccessRoles} userRole={userStore?.user?.role as string} label="FAQ" Icon={AutotrackingIco} />
				<ProtectedLink to={paths.contacts} roles={contactsAccessRoles} userRole={userStore?.user?.role as string} label="Контакты" Icon={AutotrackingIco} />
				<ProtectedLink to={paths.users} roles={usersAccessRoles} userRole={userStore?.user?.role as string} label="Пользователи" Icon={AutotrackingIco} />


			</div>

			<div className={styles.menuDown}>

				<Link to='/profile' className={styles.profile}>
					<Avatar size='48px' setting={true}/>
					<div>
						<p className={styles.name}>{name}</p>
						<p className={styles.email}>{email}</p>
					</div>
				</Link>

				<div className={styles.exit} onClick={exit}>
					<Exit/>
					<p>Выход</p>
				</div>

			</div>
		</div>
	);
});