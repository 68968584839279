
import { FaqModel } from '../../models/faq';
import ApiClient from '../ApiClient';
import { NiceResponse } from '../dto/NiceResponse';
import { FaqCreateRequest } from './dto/FaqCreateRequest.dto';

export class FaqApi {
	constructor(private api: ApiClient) { }

	path = '/faq';

	async create(req: FaqCreateRequest) {
		
		const resp = await this.api.client.post<NiceResponse<null>>(this.path + '/create', {...req} );
		return resp.data;
	}

	async update(req: FaqCreateRequest, id: string) {
		const resp = await this.api.client.put<NiceResponse<null>>(this.path + '/update/' + id, {...req} );
		return resp.data;
	}

	async delete(id: string) {
		const resp = await this.api.client.delete<NiceResponse<null>>(this.path + '/delete/' + id);
		return resp.data;
	}

	async get(skip: number, take: number) {
		const resp = await this.api.client.get<NiceResponse<FaqModel[]>>(this.path + '/list?skip=' + skip + '&take=' + take);
		return resp.data;
	}
}