import { Routes, Route} from 'react-router-dom';
import { MainContextProvider } from '../context/storeContext';
import { SetPageTitle } from '../components/global/PageTitles/SetPageTitle';
import { Notification } from '../components/global/Notification/Notification';
import Preloader from '../components/global/preloader/Preloader';
import RequireAuth from '../hooks/RequireAuth';
import createPropsApiClient from '../context/apiClient';
import { Layout } from '../components/layout/Layout';
import { LoginPage } from '../pages/auth/login';
import ProfilePage from '../pages/profile/Profile';
import MenuMobile from '../pages/menu/Menu';
import Users from '../pages/users/Users';
import Meditations from '../pages/meditations/Meditations';
import Banners from '../pages/banners/Banners';
import MessageDay from '../pages/messageDay/MessageDay';
import Videos from '../pages/videos/Videos';
import Faq from '../pages/faq/Faq';
import Contacts from '../pages/contacts/Contacts';

export const AllRoutes = (): JSX.Element => {
	const apiProps = createPropsApiClient();

	return (
		<MainContextProvider api={apiProps}>
			<SetPageTitle />
			<Notification />
			<Preloader />
			<Routes>
				<Route path="/" element={<RequireAuth><LoginPage /></RequireAuth>} />
				<Route path='/' element={<Layout />}>
					<Route path='/profile' element={<RequireAuth><ProfilePage/></RequireAuth>}/>
					<Route path='/menu' element={<RequireAuth><MenuMobile/></RequireAuth>}/>
					<Route path='/users' element={<RequireAuth><Users/></RequireAuth>}/>
					<Route path='/meditations' element={<RequireAuth><Meditations/></RequireAuth>}/>
					<Route path='/banners' element={<RequireAuth><Banners/></RequireAuth>}/>
					<Route path='/message-day' element={<RequireAuth><MessageDay/></RequireAuth>}/>
					<Route path='/videos' element={<RequireAuth><Videos/></RequireAuth>}/>
					<Route path='/faq' element={<RequireAuth><Faq/></RequireAuth>}/>
					<Route path='/contacts' element={<RequireAuth><Contacts/></RequireAuth>}/>
				</Route>
			</Routes>
		</MainContextProvider>
	);
};