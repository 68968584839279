import { makeAutoObservable, runInAction } from 'mobx';
import MainStore from './mainStore';

export default class NotificationsStore {
	mainStore: MainStore;

	showError = false;
	errorMessage = '';

	showNotifications = false;
	notificationMessage = '';

	preloader = false;

	constructor(mainStore: MainStore) {
		makeAutoObservable(this);

		this.mainStore = mainStore;
	}

	setError = (error: string) => {
		runInAction(() => {
			this.showError = true;
			this.errorMessage = error;
		});

		setTimeout(() => {
			runInAction(() => {
				this.showError = false;
				this.errorMessage = '';
			});
		}, 3000);
	}

	setNotifications = (notifications: string) => {
		runInAction(() => {
			this.showNotifications = true;
			this.notificationMessage = notifications;
		});

		setTimeout(() => {
			runInAction(() => {
				this.showNotifications = false;
				this.notificationMessage = '';
			});
		}, 3000);
	}

	showPreloader = () => {
		runInAction(() => {
			this.preloader = true;
		});
	}

	hidePreloader = () => {
		runInAction(() => {
			this.preloader = false;
		});
	}
}