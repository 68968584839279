/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import styles from './contacts.module.css';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
require('moment/locale/ru');

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { Button } from '../../components/UI/Button/Button';
import { ContactModel } from '../../models/contact';
import AccessPopUP from '../../components/popUp/Access/Access';
import { Input } from '../../components/UI/input/input';

const Contacts: React.FC = observer(() => {

	const api = useApi();
	const store = useStores();

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);

	const [contacts, setContacts] = useState<ContactModel[]>([]);

	const [total, setTotal] = useState<number>(0);

	useEffect(() => {
		loadContacts();
	}, [page, rowsPerPage]);

	const loadContacts = async () => {
		try {
			store.notificationsStore.showPreloader();

			let searchString: string | null = null

			if (searchText !== '' && searchText.length > 2) {
				searchString = searchText;
			}

			const resp = await api.contact.get(page * rowsPerPage, rowsPerPage, searchString);
	
			if (resp.body) {
				setContacts(resp.body);
			}

			if (resp.total) {
				setTotal(resp.total);
			}

		} catch (e) {
			store.notificationsStore.setError('Ошибка при загрузке')
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	}

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	}

	const [open, setOpen] = useState(false);
	const [idContact, setIdContact] = useState<string>('');

	const handleOpen = (id: string) => {
		setIdContact(id);
		setOpen(true);
	}

	const handleClose = () => {
		setOpen(false);
	}

	const [searchText, setSearchText] = useState<string>('');

	useEffect(() => {
		if (searchText.length > 2) {
			setPage(0);
			loadContacts();
		}
	}, [searchText]);

	return (
		<>
			{open && <AccessPopUP contactId={idContact} onClose={handleClose}/>}
			<div className={styles.container}>
				<div className={styles.pageName}>
					<div className={styles.top}>
						<div className={styles.title}>
							<h2>Контакты</h2>
						</div>
						<Input
							textLabel='Поиск по email'
							placeholder='Поиск по email'
							onChange={(e) => setSearchText(e.target.value)}
						/>
					</div>

				</div>

				<div style={{ width: '100%', maxWidth: 1500, margin: '5px' }}>

					<Paper sx={{ width: '100%', maxWidth: 1500, margin: '5px', overflowX: 'auto' }}>
						<TableContainer 
							style={{marginTop: 20, overflowX: 'auto'}}>
							<Table sx={{ minWidth: 320 }}>
								<TableHead>
									<TableRow>
										<TableCell>Email</TableCell>
										<TableCell align="right">Имя</TableCell>
										<TableCell align="right">Телефон</TableCell>
										<TableCell align="right">Страна</TableCell>
										<TableCell align="right">Доступы</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{contacts && contacts
										.map((row) => (
											<TableRow
												key={row.contact_id}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<>
													<TableCell component="th" scope="row">
														{row.email}
													</TableCell>
													<TableCell align="right">{row.first_name ? row.first_name : 'Имя не указано' + row.last_name ? row.last_name : ''}</TableCell>
													<TableCell align="right">{row.phone ? row.phone : 'Телефон не указан'}</TableCell>
													<TableCell align="right">{row.country ? row.country : 'Страна не указана'}</TableCell>
													<TableCell align="right">
														<Button style={{width: 180}} onClick={() => handleOpen(row.contact_id)} appearance={'link'}>Показать доступы</Button>
													</TableCell>
												</>

											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
						<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
						<TablePagination
							labelRowsPerPage="Строк на странице"
							labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count !== -1 ? count : `больше чем ${to}`}`}
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={total}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Paper>

				</div>
				

			</div>

		</>
	);
});

export default Contacts;