import styles from './input.module.css';
import cn from 'classnames';
import { InputProps } from './Input.props';
import { forwardRef } from 'react';
import { ForwardedRef } from 'react';

export const Input = forwardRef(({textLabel, type, id, placeholder, error, style, className, autoComplete, ...props}: InputProps , ref: ForwardedRef<HTMLInputElement>): JSX.Element => {
	
	return(
		type === 'checkbox' ? 
			<div className={cn(styles.checkboxContainer, className)}>
				<p>{textLabel}</p>
				<input
					className={cn(styles.checkboxFieldInput, {
						[styles.inputError]: error !== undefined
					})} 
					type={type}
					id={id}
					placeholder={placeholder}
					autoComplete={autoComplete}
					{...props}
					ref={ref}
				/>
			</div> 
			:
			<div className={cn(styles.textField, styles.textFieldFloating, className)} style={style}>
				<input
					className={cn(styles.textFieldInput, {
						[styles.inputError]: error !== undefined
					})} 
					type={type}
					id={id}
					placeholder={placeholder}
					autoComplete={autoComplete}
					{...props}
					ref={ref}
				/>
				<label className={styles.textFieldLabel} htmlFor={type}>{textLabel}</label>
				{error && <span className={styles.error}>{error?.message as string}</span>}
			</div>
	);
});