export const getTitleName = (location: string): string => {
	if (location === '/') { return 'Вход | MUIP'; }
	if (location === '/registration') { return 'Регистрация | MUIP'; }
	if (location === '/forget-password') { return 'Востановление пароля | MUIP'; }
	if (location === '/reset-password') { return 'Изменение пароля | MUIP'; }

	if (location === '/profile') { return 'Профиль | MUIP'; }
	if (location === '/meditations') { return 'Медитации | MUIP'; }
	

	else { return 'MUIP'; }
};