import { AccessModel } from '../../models/access';
import { ContactModel } from '../../models/contact';
import ApiClient from '../ApiClient';
import { NiceResponse } from '../dto/NiceResponse';

export class ContactApi {
	constructor(private api: ApiClient) { }

	path = '/contacts';

	async get(skip: number, take: number, email?: string | null) {
		const params: { skip: number; take: number; email?: string  } = {
			skip: skip,
			take: take,
		}
	
		if (email && email !== '') {
			params.email = email;
		}

		const resp = await this.api.client.get<NiceResponse<ContactModel[]>>(this.path + '/list', { params });
		return resp.data;
	}

	async getAccessProducts(id: string) {
		const resp = await this.api.client.get<NiceResponse<AccessModel[]>>(this.path + '/access-products/' + id);
		return resp.data;
	}
}