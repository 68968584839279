/* eslint-disable no-console */
import axios, { AxiosInstance } from 'axios';
import ApiClientProps from './ApiClientProps';
import { UserApi } from './user/User';
import { UserAuthResponse } from './user/dto/UserAuthResponse.dto';
import { NiceResponse } from './dto/NiceResponse';
import MainStore from '../stores/mainStore';
import { MeditationsApi } from './meditations/Meditations';
import { BannersApi } from './banners/Banners';
import { MessageDayApi } from './messageDay/MessageDay';
import { VideosApi } from './videos/Videos';
import { FaqApi } from './faq/Faq';
import { ContactApi } from './contact/Contact';

export default class ApiClient {
	client: AxiosInstance;
	baseUrl: string;
	private props: ApiClientProps;
	private store: MainStore;

	private isRefreshing = false;
	private refreshSubscribers: Array<(token: string) => void> = [];

	constructor(props: ApiClientProps, store: MainStore) {
		this.props = props;
		this.baseUrl = this.props.baseUrl;
		this.store = store;

		this.client = axios.create({
			baseURL: this.baseUrl,
		});

		this.setInterceptors();
	}

	public getAccessToken(): string | undefined | null {
		return this.props.accessToken || null;
	}

	public getRefreshToken(): string | undefined | null {
		return this.props.refreshToken || null;
	}

	public saveTokens(accessToken: string | null | undefined, refreshToken: string | null | undefined) {
		if (accessToken) {
			this.props.accessToken = accessToken;
			this.props.saveToken(this.props.auth.access.name, accessToken);
		} else {
			this.props.accessToken = null;
			this.props.deleteToken(this.props.auth.access.name);
		}

		if (refreshToken) {
			this.props.refreshToken = refreshToken;
			this.props.saveToken(this.props.auth.refresh.name, refreshToken);
		} else {
			this.props.refreshToken = null;
			this.props.deleteToken(this.props.auth.refresh.name);
		}

		this.user.me().then((res) => {
			this.store.userStore.setUser(res.body);
		}).catch((error) => {
			console.error('Failed to fetch user data:', error);
		});
	}

	public async unauthorize() {
		this.saveTokens(null, null);
	}

	public isAuthorized() {
		return this.getAccessToken() != null && this.getRefreshToken() != null;
	}

	private subscribeTokenRefresh(cb: (token: string) => void) {
		this.refreshSubscribers.push(cb);
	}

	private onRefreshed(token: string) {
		this.refreshSubscribers.forEach(cb => cb(token));
		this.refreshSubscribers = [];
	}

	private async setInterceptors() {
		this.client.interceptors.request.use(
			async config => {
				config.headers['Accept'] = 'application/json';

				const accessToken = this.getAccessToken();

				if (this.props.debug) {
					const { url, data, headers } = config;
					console.log('REQUEST:', url, JSON.stringify(data, null, 4), headers);
				}

				if (accessToken) {
					config.headers['Authorization'] = `Bearer ${accessToken}`;
				}

				return config;
			},
			async (error: any) => {
				return Promise.reject(error);
			}
		);

		this.client.interceptors.response.use(
			(response) => {
				if (this.props.debug) {
					const { status, data } = response;
					console.log('RESPONSE:', JSON.stringify(data, null, 4), 'STATUS:', status);
				}

				return response;
			},
			async (error: any) => {
				const originalRequest = error.config;
				const refreshToken = this.getRefreshToken();
				const errors = error?.response?.data?.error as string;
				const code = error?.response?.data?.code as number;
				const needToUpdateToken = code === 401;

				if (errors === 'refresh token invalid') {
					this.store.authStore.logout();
					return Promise.reject(error);
				}

				if (needToUpdateToken && refreshToken && !originalRequest._retry) {
					
					if (this.isRefreshing) {
						return new Promise((resolve) => {
							this.subscribeTokenRefresh((token: string) => {
								originalRequest.headers['Authorization'] = `Bearer ${token}`;
								resolve(this.client(originalRequest));
							});
						});
					}
					
					this.isRefreshing = true;
					originalRequest._retry = true;
					
					return this.refreshAccessTokenAndSave().then(newAccessToken => {
						if (newAccessToken) {
							this.onRefreshed(newAccessToken);
							originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
							return this.client(originalRequest);
						}
						return Promise.reject(error);
					}).catch(err => {
						return Promise.reject(err);
					}).finally(() => {
						this.isRefreshing = false;
					});
				}

				if (this.props.debug) {
					console.error('RESPONSE ERROR:', errors, 'CODE:', code);
				}

				return Promise.reject({
					success: false,
					error: error?.response?.data?.error,
					data: error,
					code: error?.response?.status
				});
			}
		);
	}

	private async refreshAccessTokenAndSave(): Promise<string | null> {
		if (this.props.debug) {
			console.log('REFRESHING TOKEN...');
		}

		const refreshToken = this.getRefreshToken();
		if (refreshToken) {
			try {
				const res = await this.client.post<NiceResponse<UserAuthResponse>>('/auth/refresh', { token: refreshToken });
				if (res.data.success) {
					const newAccessToken = res.data?.body?.access_token || null;
					const newRefreshToken = res.data?.body?.refresh_token || null;
					this.saveTokens(newAccessToken, newRefreshToken);
					return newAccessToken;
				}
				throw new Error('Failed to refresh token');
			} catch (e) {
				await this.unauthorize();
				return null;
			}
		} else {
			return null;
		}
	}
	
	user: UserApi = new UserApi(this);
	meditations: MeditationsApi = new MeditationsApi(this);
	banners: BannersApi = new BannersApi(this);
	messageDay: MessageDayApi = new MessageDayApi(this);
	videos: VideosApi = new VideosApi(this);
	faq: FaqApi = new FaqApi(this);
	contact: ContactApi = new ContactApi(this);
}
