/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import styles from './users.module.css';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
require('moment/locale/ru');

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { Button } from '../../components/UI/Button/Button';
import { ReactComponent as CrossIco } from './icon/delete.svg';
import { UserMeResponse } from '../../api/user/dto/UserMeResponse.dto';
import UserPopUP from '../../components/popUp/User/User';

const Users: React.FC = observer(() => {

	const api = useApi();
	const store = useStores();

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);

	const [showForm, setShowForm] = useState<boolean>(false);

	const [users, setUsers] = useState<UserMeResponse[]>([]);

	useEffect(() => {
		loadUsers();
	}, []);

	const loadUsers = async () => {
		try {
			store.notificationsStore.showPreloader();
			const {body} = await api.user.getUsers(page * rowsPerPage, rowsPerPage);
	
			if (body) {
				setUsers(body);
			}

		} catch (e) {
			store.notificationsStore.setError('Ошибка при загрузке данных')
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	}

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	}

	const deleteUser = async (id: string) => {
		try {
			store.notificationsStore.showPreloader();
			await api.user.deleteUser(id);
			store.notificationsStore.setNotifications('Успешно удалено');
		} catch (e) {
			store.notificationsStore.setError('Ошибка при удалении')
		} finally {
			setUsers(users.filter(spent => spent.id !== id));
			store.notificationsStore.hidePreloader();
		}
	}

	const closeForm = () => {
		setShowForm(false);
	}

	const update = () => {
		loadUsers();
	}

	return (
		<>
			{showForm && <UserPopUP onClose={closeForm} update={update} user={null}/>}
			<div className={styles.container}>
				<div className={styles.pageName}>
					<div className={styles.top}>
						<div className={styles.title}>
							<h2>Пользователи</h2>
						</div>
						<Button style={{maxWidth: 150}} appearance="auth" onClick={() => setShowForm(showForm ? false : true)}>Добавить</Button>
					</div>

				</div>

				<div style={{ width: '100%', maxWidth: 1200, margin: '5px' }}>

					<Paper sx={{ width: '100%', maxWidth: 1200, margin: '5px', overflowX: 'auto' }}>
						<TableContainer 
							style={{marginTop: 20, overflowX: 'auto'}}>
							<Table sx={{ minWidth: 320 }}>
								<TableHead>
									<TableRow>
										<TableCell>Имя</TableCell>
										<TableCell align="right">Email</TableCell>
										<TableCell align="right">Роль</TableCell>
										<TableCell align="right">Действие</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{users && users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row) => (
											<TableRow
												key={row.id}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<>
													<TableCell component="th" scope="row">
														{row.name}
													</TableCell>
													<TableCell align="right">{row.email}</TableCell>
													<TableCell align="right">{row.role}</TableCell>

													<TableCell align="right">
														<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
															{/* <EditIco className={styles.tagDeleteIco} 
																onClick={() => {
																	setEditSpentId(row.id);
																	setEditSpent(row);
																}}
															/> */}
															<CrossIco className={styles.tagDeleteIco} 
																onClick={() => deleteUser(row.id)}
															/>
														</div>
													</TableCell>

												</>

											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
						<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
						<TablePagination
							labelRowsPerPage="Строк на странице"
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={users ? users.length : 0}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Paper>

				</div>
				

			</div>

		</>
	);
});

export default Users;