export type allRoles = 'user' | 'admin';

export const usersAccessRoles = ['admin'];
export const meditationsAccessRoles = ['admin'];
export const bannersAccessRoles = ['admin'];
export const messageDayAccessRoles = ['admin'];
export const videosAccessRoles = ['admin'];
export const faqAccessRoles = ['admin'];
export const contactsAccessRoles = ['admin'];

export const allAccessRoles = [
	{id: 'nameSelect', name: 'Выберите роль пользователя'},
	{id: 'user', name: 'Пользователь'},
];