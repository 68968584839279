import React from 'react';
import styles from './preloader.module.css';
import { ReactComponent as Cricle } from './tube-spinner.svg';
import { useStores } from '../../../hooks/useStore';
import { observer } from 'mobx-react';

export const Preloader: React.FC = observer(() => {

	const { notificationsStore }  = useStores();

	return (
		<>
			{notificationsStore.preloader &&
			<div className={styles.fon}>
				<div className={styles.preloaderWrap}>
					<div className={styles.preloader}>
						<div className={styles.rocket}>
							<Cricle className={styles.icon}/>
						</div>
					</div>
				</div>
			</div>
			}
		</>
	);
});

export default Preloader;