/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from './menu.module.css';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { bannersAccessRoles, contactsAccessRoles, faqAccessRoles, meditationsAccessRoles, messageDayAccessRoles, usersAccessRoles, videosAccessRoles } from '../../utils/accessRoles';
import cn from 'classnames';
import { ReactComponent as AutotrackingIco } from '../../components/layout/Sidebar/ico/autotracking.svg';

const MenuMobile: React.FC = observer(() => {
	
	const {userStore} = useStores();

	const paths = {
		profile: '/profile',
		users: '/users',
		meditations: '/meditations',
		banners: '/banners',
		messageDay: 'message-day',
		videos: '/videos',
		faq: '/faq',
		contacts: '/contacts',
	};


	const setActiveLink = (isActive: boolean) => isActive ? cn(styles.menuItem, styles.active) : styles.menuItem;

	return (
		<div>

			<div className={styles.menu}>

				{ meditationsAccessRoles.includes(userStore?.user?.role as string) && 

				<NavLink to={paths.meditations} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>Медитации</p>
				</NavLink>

				}

				{ bannersAccessRoles.includes(userStore?.user?.role as string) && 

				<NavLink to={paths.banners} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>Баннеры</p>
				</NavLink>

				}
				
				{ messageDayAccessRoles.includes(userStore?.user?.role as string) && 

				<NavLink to={paths.messageDay} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>Послания дня</p>
				</NavLink>

				}
				
				{ videosAccessRoles.includes(userStore?.user?.role as string) && 

				<NavLink to={paths.videos} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>Видео</p>
				</NavLink>

				}

				{ faqAccessRoles.includes(userStore?.user?.role as string) && 

				<NavLink to={paths.faq} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>FAQ</p>
				</NavLink>

				}

				{ contactsAccessRoles.includes(userStore?.user?.role as string) && 

				<NavLink to={paths.contacts} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>Контакты</p>
				</NavLink>

				}

				{ usersAccessRoles.includes(userStore?.user?.role as string) && 

				<NavLink to={paths.users} className={({isActive}) => setActiveLink(isActive)}>
					<div className={styles.line}/>
					<AutotrackingIco/>
					<p>Пользователи</p>
				</NavLink>

				}

			</div>

		</div>
	);
});

export default MenuMobile;