import { makeAutoObservable, runInAction } from 'mobx';
import MainStore from './mainStore';

export default class AuthStore {
	private mainStore: MainStore;

	constructor(mainStore: MainStore) {
		this.mainStore = mainStore;

		makeAutoObservable(this);
	}
	
	login = (accessToken: string | undefined, refreshToken: string | undefined) => {
		if (!accessToken || !refreshToken) {return}
		runInAction(() => this.mainStore.apiClient.saveTokens(accessToken, refreshToken));
	};

	logout = () => {
		runInAction(() => 
		{
			this.mainStore.userStore.removeUser();
			this.mainStore.apiClient.unauthorize()
		}
		);
	};
}