/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './Access.module.css';
import { ReactComponent as Close } from '../ico/close.svg';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import { useApi } from '../../../hooks/useApi';
import { useEffect, useState } from 'react';
import { AccessModel } from '../../../models/access';

interface PopupProps {
  contactId: string;
  onClose: () => void;
}

const AccessPopUP: React.FC<PopupProps> = observer(({ contactId, onClose }) => {

	const store = useStores();
	const api = useApi();

	const [access, setAccess] = useState<AccessModel[]>([]);


	const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// Проверяем, что клик произошел именно на overlay, а не на его содержимое
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	useEffect(() => {
		getAccess();
	}, [contactId]);

	const getAccess = async () => {
		try {
			store.notificationsStore.showPreloader();
			const {body} = await api.contact.getAccessProducts(contactId);
	
			if (body) {
				setAccess(body);
			}
		} catch (error) {
			store.notificationsStore.setError('Ошибка загрузки доступов');
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}
	
	return (
		<>
			<div className={styles.container}
				onClick={handleOverlayClick}
			>
				<div className={styles.form}>
					<Close className={styles.close} onClick={onClose}/>
					<h3>Доступы контакта</h3>
					<div className={styles.line} style={{marginBottom: 20}}/>

					{access && access.length > 0 &&
					<ul style={{marginBottom: 40, width: '80%'}}>
						{access.map((item, index) => (
							<li key={index}>{item?.productName ? item?.productName : 'Все курсы'}</li>
						))}
					</ul>
					}

					{access.length === 0 &&
					<div style={{marginBottom: 40}}>
						Нет доступов
					</div>
					}

				</div>
			</div>
		</>
	);
});

export default AccessPopUP;
