/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import styles from './banners.module.css';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
require('moment/locale/ru');

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { Button } from '../../components/UI/Button/Button';
import { ReactComponent as EditIco } from './icon/edit.svg';
import { AppSettings } from '../../appSettings';
import { BannersModel } from '../../models/banners';
import BannersPopUP from '../../components/popUp/Banners/Banners';

const Banners: React.FC = observer(() => {

	const api = useApi();
	const store = useStores();

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);

	const [showForm, setShowForm] = useState<boolean>(false);

	const [banners, setBanners] = useState<BannersModel[]>([]);

	useEffect(() => {
		loadBanners();
	}, []);

	const loadBanners = async () => {
		try {
			store.notificationsStore.showPreloader();
			const {body} = await api.banners.get(page * rowsPerPage, rowsPerPage);
	
			if (body) {
				setBanners(body);
			}

		} catch (e) {
			store.notificationsStore.setError('Ошибка при загрузке')
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	}

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	}

	const closeForm = () => {
		setShowForm(false);
		setBanner(null);
	}

	const update = () => {
		loadBanners();
	}

	const [banner, setBanner] = useState<BannersModel | null>(null);

	const editItem = (item: BannersModel) => {
		setBanner(item);
		setShowForm(true);
	}

	return (
		<>
			{showForm && <BannersPopUP onClose={closeForm} update={update} item={banner}/>}
			<div className={styles.container}>
				<div className={styles.pageName}>
					<div className={styles.top}>
						<div className={styles.title}>
							<h2>Баннеры</h2>
						</div>
						<Button style={{maxWidth: 150}} appearance="auth" onClick={() => setShowForm(showForm ? false : true)}>Добавить</Button>
					</div>

				</div>

				<div style={{ width: '100%', maxWidth: 1500, margin: '5px' }}>

					<Paper sx={{ width: '100%', maxWidth: 1500, margin: '5px', overflowX: 'auto' }}>
						<TableContainer 
							style={{marginTop: 20, overflowX: 'auto'}}>
							<Table sx={{ minWidth: 320 }}>
								<TableHead>
									<TableRow>
										<TableCell>Ссылка</TableCell>
										<TableCell align="right">Изображение</TableCell>
										<TableCell align="right">Действие</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{banners && banners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row) => (
											<TableRow
												key={row.id}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<>
													<TableCell component="th" scope="row">
														{row.url}
													</TableCell>
													<TableCell align="right"><img src={AppSettings.api.url + row.image} width={200}/></TableCell>

													<TableCell align="right">
														<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
															<EditIco className={styles.tagDeleteIco} 
																onClick={() => {
																	editItem(row);
																}}
															/>
														</div>
													</TableCell>

												</>

											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
						<div style={{width: '100%', height: 1, backgroundColor: '#E0E0E0'}}/>
						<TablePagination
							labelRowsPerPage="Строк на странице"
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={banners ? banners.length : 0}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Paper>

				</div>
				

			</div>

		</>
	);
});

export default Banners;