import {  useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import Avatar from '../../components/UI/Avatar/Avatar';
import { Button } from '../../components/UI/Button/Button';
import { Input } from '../../components/UI/input/input';
import styles from './profile.module.css';
import { ReactComponent as ProfileSetting } from './user-settings.svg';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';

const ProfilePage: React.FC = observer(() => {
	
	const {register, formState: {errors}, setValue, handleSubmit} = useForm();
	const [errorOversizeAvatar, setErrorOversizeAvatar] = useState(false);

	const {userStore} = useStores();

	useEffect(() => {
		// Вызывается один раз при монтировании компонента
		setValue('name', userStore.user?.name || '');
		setValue('email', userStore.user?.email || '');
		setValue('password', 'default');
	}, [userStore.user, setValue]);


	const uploadPhoto = (file: File) => {
		if (file.size > 3000000) {
			setErrorOversizeAvatar(true);
			setTimeout(setErrorOversizeAvatar, 3000, false);
			return false;
		}

		const formData = new FormData();
		formData.append('image',file);
	};

	const onSubmit = (formData: FieldValues) => {
		console.log(formData)
		// if (formData.name === userState.user.name && formData.password === 'default') {
		// 	despatch({type: TypesAction.error.SET_ERROR, message: 'You have not changed any fields'});
		// 	return false;
		// }
		// despatch({type: TypesAction.user.UPDATE_USER_INFO, payload: {
		// 	name: formData.name !== userState.user.name ? formData.name : null,
		// 	password: formData.password !== 'default' ? formData.password : null,
		// 	token: cookies.token
		// }});
	};

	return (
		<div className={styles.container} id='profile'>
			<div className={styles.title}>
				<ProfileSetting/>
				<h2>Настройки профиля</h2>
			</div>

			<div className={styles.content}>
				<div className={styles.avatar}>
					<Avatar profile={true}/>
					<input type='file' name='file' id='file' className={styles.inputfile} accept='image/*'
						onChange={e => {if (e?.target?.files){uploadPhoto(e?.target?.files[0]);}}}
					/>
					{/* <label htmlFor='file' className={styles.inputButton}>Изменить фото</label>
					{errorOversizeAvatar && <p>Размер фото,<br/>не должен превышать 3 МБ</p>} */}
				</div>

				<div className={styles.setting}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Input 
							textLabel='Имя' type='name' id='name' placeholder='Имя'
							{...register('name', {required: {value: true, message: 'Имя обзятельно'}})}
							error={errors?.name}
						/>

						<Input 
							textLabel='Email' type='email' id='email' placeholder='Email' disabled
							{...register('email', {required: {value: true, message: 'Email обзятелен'}})}
							error={errors?.email}
						/>

						<Input 
							textLabel='Введите новый пароль' type='password' id='password' placeholder='Введите пароль'
							{...register('password', {required: {value: true, message: 'Введите пароль'}})}
							error={errors?.password}
						/>
						<Button appearance='auth'>Сохранить данные</Button>
					</form>
				</div>

			</div>
		</div>
	);
});

export default ProfilePage;