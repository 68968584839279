/* eslint-disable no-unused-vars */
import ApiClient from '../ApiClient';
import { NiceResponse } from '../dto/NiceResponse';
import { UserAuthRequest } from './dto/UserAuthRequest';
import { UserAuthResponse } from './dto/UserAuthResponse.dto';
import { UserForgotRequest } from './dto/UserForgotRequest.dto';
import { UserMeResponse } from './dto/UserMeResponse.dto';
import UserRegistrRequest from './dto/UserRegistrRequest.dto';
import { UserRegistrResponse } from './dto/UserRegistrResponse.dto';

export class UserApi {
	constructor(private api: ApiClient) { }

	path = '/auth';

	async login(req: UserAuthRequest) {
		const resp = await this.api.client.post<NiceResponse<UserAuthResponse>>(this.path + '/login', { ...req });
		return resp.data;
	}

	async forgotPassword(req: UserForgotRequest) {
		const resp = await this.api.client.put<NiceResponse<null>>(this.path + '/forgot-password', { ...req });
		return resp.data;
	}

	async registration(req: UserRegistrRequest) {
		const resp = await this.api.client.post<NiceResponse<UserRegistrResponse>>(this.path + '/register', { ...req });
		return resp.data;
	}

	async me() {
		const resp = await this.api.client.get<NiceResponse<UserMeResponse>>('/user/info');
		return resp.data;
	}

	async getUsers(skip: number, take: number) {
		const resp = await this.api.client.get<NiceResponse<UserMeResponse[]>>(`/user/users?skip=${skip}&take=${take}`);
		return resp.data;
	}

	async deleteUser(id: string) {
		const resp = await this.api.client.delete<NiceResponse<null>>(`/user/delete/${id}`);
		return resp.data;
	}
}