import React, { ForwardedRef, forwardRef } from 'react';
import styles from './TextArea.module.css';
import cn from 'classnames';
import { TextAreaProps } from './TextArea.props';

export const TextArea = forwardRef(({textLabel, name, error, className, maxWidth, ...props}: TextAreaProps, ref: ForwardedRef<HTMLTextAreaElement>): JSX.Element => {
	
	return(
		<div style={{maxWidth: maxWidth}} className={cn(styles.textField, styles.textFieldFloating)}>
			<textarea
				className={cn(styles.textFieldInput, className, {
					[styles.inputError]: error !== undefined,
				})}
				{...props}
				name={name}
				ref={ref}
			/>
			<label className={styles.textFieldLabel} htmlFor={name}>{textLabel}</label>
			<div className={styles.substrate}/>
			{error && <span className={styles.error}>{error.message}</span>}
		</div>
	);
});