/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import styles from './Faq.module.css';
import { ReactComponent as Close } from '../ico/close.svg';
import { ReactComponent as DeleteIco } from '../ico/delete.svg';
import { Button } from '../../UI/Button/Button';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import { useApi } from '../../../hooks/useApi';
import { Input } from '../../UI/input/input';
import { useEffect, useState } from 'react';
import { FaqModel } from '../../../models/faq';
import { TextArea } from '../../UI/TextArea/TextArea';

interface PopupProps {
  item: FaqModel | null;
  onClose: () => void;
	update: () => void;
}

const FaqPopUP: React.FC<PopupProps> = observer(({ item, onClose, update }) => {

	const store = useStores();
	const api = useApi();

	const [question, setQuestion] = useState<string>('');
	const [answer, setAnswer] = useState<string>('');

	const [disable, setDisable] = useState<boolean>(true);

	useEffect(() => {
		if (item) {
			setQuestion(item.question ?? '');
			setAnswer(item.answer ?? '');
		}
	}, [item]);

	useEffect(() => {
		if (question !== '' && answer !== '') {
			setDisable(false);
		} else {
			setDisable(true);
		}
	},[question, answer]);


	// const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
	// 	// Проверяем, что клик произошел именно на overlay, а не на его содержимое
	// 	if (event.target === event.currentTarget) {
	// 		onClose();
	// 	}
	// };

	const handleButtonClick = async () => {
		try {
			store.notificationsStore.showPreloader();

			if (item) {
				await api.faq.update({
					question,
					answer,
				}, item.id as string);
			} else {
				await api.faq.create({
					question,
					answer,
				});
			}

			update();
			onClose();

			store.notificationsStore.setNotifications('Успешно выполнено');

		} catch (e) {
			store.notificationsStore.setError('Ошибка при выполнении')
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const deleteItem = async () => {
		try {
			store.notificationsStore.showPreloader();
			await api.faq.delete(item?.id as string);
			store.notificationsStore.setNotifications('Успешно удалено');
			update();
			onClose();
		} catch (e) {
			store.notificationsStore.setError('Ошибка при удалении')
		} finally {
			store.notificationsStore.hidePreloader();
		}
	}

	const [showApproveDelete, setShowApproveDelete] = useState<boolean>(false);
	
	return (
		<>
			<div className={styles.container} 
			// onClick={handleOverlayClick}
			>
				<div className={styles.form}>
					<Close className={styles.close} onClick={onClose}/>
					<h3>{!item ? 'Добавление' : 'Изменение'} вопроса</h3>
					<div className={styles.line} style={{marginBottom: 20}}/>

					<Input style={{maxWidth: '90%'}} value={question} placeholder='Вопрос' textLabel='Вопрос' onChange={(event) => setQuestion(event.target.value)}/>

					<TextArea maxWidth={'90%'} value={answer} placeholder='Ответ' textLabel='Ответ' onChange={(event) => setAnswer(event.target.value)}/>

					<div className={styles.line} style={{marginBottom: 10}}/>

					<div style={{display: 'flex', width: '90%', justifyContent: 'center'}}>
						{ !showApproveDelete && <Button disabled={disable} onClick={handleButtonClick} style={{margin: 5, maxWidth: 320, marginBottom: 25}} appearance='auth'>{!item ? 'Создать' : 'Изменить'}</Button>}
						{ item && !showApproveDelete && <Button onClick={() => setShowApproveDelete(true)} style={{margin: 5, maxWidth: 320, marginBottom: 25}} appearance='link'>Удалить</Button> }

						{showApproveDelete &&
						<div style={{display: 'flex', width: '90%', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
							<p style={{marginBottom: 10}}>Точно хотите удалить?</p>
							<div style={{display: 'flex', width: '90%', justifyContent: 'center'}}>
								<Button disabled={disable} onClick={deleteItem} style={{margin: 5, maxWidth: 320, marginBottom: 25}} appearance='link'>Удалить</Button>
								<Button disabled={disable} onClick={() => setShowApproveDelete(false)} style={{margin: 5, maxWidth: 320, marginBottom: 25}} appearance='auth'>Отмена</Button>
							</div>
						</div>
						}
					</div>


				</div>
			</div>
		</>
	);
});

export default FaqPopUP;
